<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos de la ruleta fisica" ref="pageForm" :isAdmin="isItForAdmin()">
      <template>
        <PageForm :crupiers="crupiers" :typeform="typeform" @closeModa="closeModa" :jackpot-form="jackpot" />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row d-flex">
              <div class="col-6">
                <Tooltip>
                  <ol>
                    <li>
                      Agregar:
                      <ol>
                        <li>Tipo de ruleta: 00, y sencilla</li>
                        <li>Tiempo para apostar en segundos</li>
                        <li>
                          Minutos para desactivar la ruleta en caso de no recibir
                          rondas
                        </li>
                        <li>URL para transmitir video</li>
                        <li>Crupier</li>
                        <li>Jackpot: ¿La ruleta posee multiplicadores?</li>
                      </ol>
                    </li>
                    <li>Acciones: acá puedes editar lo anteriormente señalado</li>
                  </ol>
                </Tooltip>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <change-view :destiny="$t('routeMenu.rouletteVirtual')" route="/roulettes"></change-view>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                      }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" primary class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" style="overflow: visible">
              <b-table :items="tableData" :fields="fields" responsive="sm">
                <!-- Column: Actions -->
                <template #cell(actions)="data" class="d-flex align-items-center">
                  <div>
                    <b-dropdown variant="link" no-caret>
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <!-- <b-dropdown-item
                        :to="{
                          name: 'currencie-show',
                          params: { id: data.item._id },
                        }"
                      >
                        <i class="uil-file-alt m-2 font-size-18" />
                        <span class="align-middle ml-5">Detalles</span>
                      </b-dropdown-item> -->

                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">{{ $t('actions.subItems.edit') }}</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PageForm from "./form.vue";
import { mapMutations, mapGetters } from "vuex";
import { Tooltip, ChangeView } from "../../../../components";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Roulette Fisics Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, PageForm, Tooltip, ChangeView },
  data() {
    return {
      tableData: [],

      title: this.$t('routeMenu.rouletteFisic'),
      crupiers: [],
      items: [
        {
          text: "Tables",
        },
        {
          text: "rouletteFisics",
          active: true,
        },
      ],
      fields: [
        {
          key: "name",
          sortable: true,
          label: this.$t('roulettes.name').toUpperCase(),
          class: "text-center",
        },
        {
          key: "providerId",
          sortable: true,
          label: this.$t('roulettes.rouletteFisic.providerId').toUpperCase(),
          class: "text-center",
        },
        {
          key: "actions",
          sortable: false,
          label: this.$t('actions.text').toUpperCase(),
        },
      ],

      typeform: {
      },
      perPage: 10,

      pageOptions: [10, 25, 50, 100],
      filter: null,
      currentPage: 1,

      jackpot: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    ...mapGetters({
      isJackpot: 'games/getJackpot'
    })

  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
    this.getUserRole();
    this.getCrupiers();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.$http
        .get("/roulettes/fisics/get")
        .then(({ data }) => {
          if (!data.rouletteFisics) {
            return console.log("No hay rouletteFisics", data.rouletteFisics);
          }
          this.tableData = data.rouletteFisics;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModa() {
      this.$refs.pageForm.modal = false;

      this.typeform = {};
      this.getData();
    },
    edit(item) {
      this.typeform = item;
      this.setJackpot(this.typeform.jackpot);
      this.jackpot = this.isJackpot;
      this.$refs.pageForm.modal = true;
    },
    getCrupiers() {
      this.$http
        .get("/crupiers")
        .then((response) => {
          this.crupiers = response.data.crupiers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapMutations({
      setJackpot: 'games/setJackpot'
    })
  },
  middleware: "authentication",
};
</script>
