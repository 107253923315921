<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.type") }}: </label>
          <select
            v-model="typeform.doubleZero"
            class="form-select border-0 shadow-lg">
            <option :value="false">Ruleta - Sencilla</option>
            <option :value="true">Ruleta - 00</option>
          </select>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("roulettes.name") }}</label>
            <vs-input
              v-model="typeform.name"
              placeholder="Nombre"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }" />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback">
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("roulettes.rouletteFisic.providerId") }}</label>
            <vs-input
              v-model="typeform.providerId"
              :placeholder="$t('roulettes.rouletteFisic.providerId')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.providerId.$error,
              }" />
            <div
              v-if="typesubmit && $v.typeform.providerId.$error"
              class="invalid-feedback">
              <span v-if="!$v.typeform.providerId.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("roulettes.rouletteFisic.roundDuration") }}</label>
            <vs-input
              v-model="typeform.roundDuration"
              :placeholder="$t('roulettes.rouletteFisic.roundDuration')"
              border
              class="shadow-lg"
              :class="{
                'is-invalid': typesubmit && $v.typeform.roundDuration.$error,
              }" />
            <div
              v-if="typesubmit && $v.typeform.roundDuration.$error"
              class="invalid-feedback">
              <span v-if="!$v.typeform.roundDuration.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("roulettes.rouletteFisic.minutesDisabled") }}</label>
            <vs-input
              v-model="typeform.minutesToDisable"
              :placeholder="$t('roulettes.rouletteFisic.minutesDisabled')"
              border
              class="shadow-lg"
              name="minutesToDisable" />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.video") }}</label>
          <vs-input
            v-model="typeform.urlTransmision"
            :placeholder="$t('roulettes.rouletteFisic.video')"
            border
            class="shadow-lg"
            :class="{
              'is-invalid': typesubmit && $v.typeform.urlTransmision.$error,
            }" />
          <div
            v-if="typesubmit && $v.typeform.urlTransmision.$error"
            class="invalid-feedback"></div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.time1") }}</label>
          <vs-input
            v-model="typeform.timeOne"
            :placeholder="$t('roulettes.rouletteFisic.time1')"
            border
            class="shadow-lg"
            :class="{
              'is-invalid': typesubmit && $v.typeform.timeOne.$error,
            }" />
          <div
            v-if="typesubmit && $v.typeform.timeOne.$error"
            class="invalid-feedback">
            <span v-if="!$v.typeform.timeOne.numeric"
              >Este campo es numerico</span
            >
            <span v-if="!$v.typeform.timeOne.required"
              >Este campo es necesario</span
            >
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.time2") }}</label>
          <vs-input
            v-model="typeform.timeTwo"
            :placeholder="$t('roulettes.rouletteFisic.time2')"
            border
            class="shadow-lg"
            :class="{
              'is-invalid': typesubmit && $v.typeform.timeTwo.$error,
            }" />
          <div
            v-if="typesubmit && $v.typeform.timeTwo.$error"
            class="invalid-feedback">
            <span v-if="!$v.typeform.timeTwo.numeric"
              >Este campo es numerico</span
            >
            <span v-if="!$v.typeform.timeTwo.required"
              >Este campo es necesario</span
            >
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.time3") }}</label>
          <vs-input
            v-model="typeform.timeThree"
            :placeholder="$t('roulettes.rouletteFisic.time3')"
            border
            class="shadow-lg"
            :class="{
              'is-invalid': typesubmit && $v.typeform.timeThree.$error,
            }" />
          <div
            v-if="typesubmit && $v.typeform.timeThree.$error"
            class="invalid-feedback">
            <span v-if="!$v.typeform.timeThree.numeric"
              >Este campo es numerico</span
            >
            <span v-if="!$v.typeform.timeThree.required"
              >Este campo es necesario</span
            >
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.time4") }}</label>
          <vs-input
            v-model="typeform.timeFour"
            :placeholder="$t('roulettes.rouletteFisic.time4')"
            border
            class="shadow-lg" />
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.aditionalTime") }}</label>
          <vs-input
            v-model="typeform.aditionalTime"
            :placeholder="$t('roulettes.rouletteFisic.aditionalTime')"
            border
            class="shadow-lg"
            :class="{
              'is-invalid': typesubmit && $v.typeform.aditionalTime.$error,
            }" />
          <div
            v-if="typesubmit && $v.typeform.aditionalTime.$error"
            class="invalid-feedback">
            <span v-if="!$v.typeform.aditionalTime.numeric"
              >Este campo es numerico</span
            >
            <span v-if="!$v.typeform.aditionalTime.required"
              >Este campo es necesario</span
            >
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{ $t("roulettes.rouletteFisic.timeReleaseJackpot") }}</label>
          <vs-input
            v-model="typeform.timeToReleaseJack"
            :placeholder="$t('roulettes.rouletteFisic.timeReleaseJackpot')"
            border
            class="shadow-lg"
            :class="{
              'is-invalid': typesubmit && $v.typeform.timeToReleaseJack.$error,
            }" />
          <div
            v-if="typesubmit && $v.typeform.timeToReleaseJack.$error"
            class="invalid-feedback">
            <span v-if="!$v.typeform.timeToReleaseJack.required"
              >Este campo es necesario</span
            >
            <span v-if="!$v.typeform.timeToReleaseJack.numeric"
              >Este campo es numerico</span
            >
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label>{{
            $t("roulettes.rouletteFisic.timeToStartAnimation")
          }}</label>
          <vs-input
            v-model="typeform.timeToStartAnimation"
            :placeholder="$t('roulettes.rouletteFisic.timeToStartAnimation')"
            border
            class="shadow-lg"
            :class="{
              'is-invalid':
                typesubmit && $v.typeform.timeToStartAnimation.$error,
            }" />
          <div
            v-if="typesubmit && $v.typeform.timeToStartAnimation.$error"
            class="invalid-feedback">
            <span v-if="!$v.typeform.timeToStartAnimation.required"
              >Este campo es necesario</span
            >
            <span v-if="!$v.typeform.timeToStartAnimation.numeric"
              >Este campo es numerico</span
            >
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("roulettes.rouletteFisic.crupier") }}</label>
            <div>
              <select
                v-model="typeform.crupier"
                name="crupier"
                class="form-select border-0 shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.crupier.$error,
                }"
                placeholder="Seleccione..">
                <option disabled selected>{{ $t("helpers.select") }}...</option>
                <option
                  v-for="(item, index) in crupiers"
                  :key="index"
                  :value="item._id"
                  :selected="typeform.crupier == item._id">
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.crupier.$error"
                class="invalid-feedback">
                <span v-if="!$v.typeform.crupier.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4 mt-3">
        <b-form-checkbox :checked="getJackpot" @change="handleChange">
          Jackpot
        </b-form-checkbox>
      </b-row>
      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button type="submit">{{ $t("form.save") }}</vs-button>
          <vs-button @click="$emit('closeModa')" type="button" success>
            {{ $t("form.abort") }}
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
    crupiers: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    jackpotForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getJackpot() {
      return this.jackpotForm ? true : false;
    },
    ...mapGetters({
      isJackpot: "games/getJackpot",
    }),
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      providerId: {
        required,
      },
      crupier: {},
      jackpot: {},
      roundDuration: {
        required,
      },
      minutesToDisable: {
        required,
      },
      urlTransmision: {
        required,
      },
      doubleZero: {
        required,
      },
      timeOne: {
        required,
      },
      timeTwo: {
        required,
      },
      timeThree: {
        required,
      },
      aditionalTime: {
        required,
      },
      timeToReleaseJack: {
        required,
      },
      timeToStartAnimation: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.typesubmit = true;
      const dataToSave = {
        ...this.typeform,
        jackpot: this.isJackpot,
      };

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
        Swal.fire("Valide la informacion por favor", "", "info");
      } else {
        if (this.typeform._id) {
          this.$http
            .put(`roulettes/fisics/${this.typeform._id}`, {
              ...dataToSave,
            })
            .then((response) => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Ruleta fisica guardada con exito",
                showConfirmButton: false,
                timer: 1500,
              });
              console.log(response);
              this.$emit("closeModa");
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          this.$http
            .post("roulettes/fisics", {
              ...dataToSave,
            })
            .then((response) => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Ruleta fisica registrada con exito",
                showConfirmButton: false,
                timer: 1500,
              });
              console.log(response);
              this.$emit("closeModa");
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    },
    handleChange(value) {
      this.setJackpot(value);
    },
    ...mapMutations({
      setJackpot: "games/setJackpot",
    }),
  },
};
</script>
